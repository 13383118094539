<template>
  <v-container fluid
    v-show="openForm"
  >
    <v-overlay
      :value="openForm"
      z-index="5"
    ></v-overlay>
    <v-row justify="center" align="center">
      <v-card class="wrap-form"
        max-width="700"
        max-height="85vh"
      >
      <!-- <v-dialog
        v-model="openForm"
        max-width="800"
        persistent
        transition="dialog-bottom-transition"
      > -->
        <v-form ref="form">
          <v-card class="pa-5">
            <v-card-title class="mb-5">
              <h2>{{ formTitle }}</h2>
            </v-card-title>

            <v-card-text class="pb-0">
              <!-- タイトル -->
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model.trim="mailmaga.title"
                    outlined
                    required
                    persistent-hint
                    label="メール件名"
                    counter="30"
                    :rules="[valiRules.required, valiRules.max30]"
                  ></v-text-field>
                </v-col>
                <!-- 本文 -->
                <v-col cols="12" class="pt-0">
                  <tiny-editor
                    v-bind:text.sync="mailmaga.text"
                    :apiAdmin="apiAdmin"
                    :shopData="shopData"
                    :max="textMax"
                    :height="500"
                  />
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                depressed
                :disabled="!isFormChanged"
                color="primary"
                @click="formSubmitted"
              >
                <span
                  v-if="!loading"
                >{{ formButton }}</span>
                <v-progress-circular
                  v-if="loading"
                  indeterminate
                  color="accent"
                ></v-progress-circular>
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="close"
              >
                閉じる
              </v-btn>
            </v-card-actions>

            <!-- ローダー -->
            <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              absolute top
              color="primary"
            ></v-progress-linear>
          </v-card>
        </v-form>
      <!-- </v-dialog> -->
      </v-card>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

  </v-container>
</template>

<script>
import $literals from '@/literals.js'
import { ValidationRules } from '@/module.js'
import TinyEditor from "@/components/_TinyEditor.vue"

export default {
  components: {
    'tiny-editor': TinyEditor,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      mailmaga: {title: '', text: ''},
      textMax: 10000,
      siteSetting: {secondary_color: 'white'},
      openForm: false,
      isFormChanged: false,
      formType: '',
      comeBack: {},
      submitCallback: null,
      loading: false,
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
    }
  },

  computed: {
    formTitle() {
      return this.formType === 'create' ? '新規メルマガ登録' : 'メルマガ編集'
    },
    formButton() {
      return this.formType === 'create' ? '登録' : '更新'
    },
    mailBodyPlaceholder() {
      return "メール本文（" + this.textMax + "文字以内）"
    }
  },

  watch: {
    openForm: function(now, old) {
      if (now !== old) {
        this.isFormChanged = false
      }
    },
    mailmaga: {
      handler: function(now, old) {
        if (Object.keys(old).length && now.mailmaga_id === old.mailmaga_id) {
          this.isFormChanged = true
        }
      },
      deep: true
    },
    'mailmaga.text': function(now) {
      if (now.length > this.textMax) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.warningTextLengthOver, open: true}}
      }
    }
  },

  methods: {
    open(handdownData) {
      this.formType = handdownData.formType
      this.submitCallback = handdownData.submitCallback
      this.comeBack = handdownData.comeBack
      this.snackbar.message = ''

      if (this.formType === 'create') {
        this.mailmaga = {
          title: '',
          text: '',
        }
      } else {
        this.mailmaga = {
          mailmaga_id: handdownData.updateData.mailmaga_id,
          title: handdownData.updateData.title,
          text: handdownData.updateData.text,
        }
      }
      this.isFormChanged = false
      this.openForm = true
    },

    close() {
      this.submitCallback = null
      this.loading = false
      this.isFormChanged = false
      this.openForm = false
    },

    formSubmitted() {
      if (!this.$refs['form'].validate()) {
        this.snackbar.message = $literals.MESSAGE.validationFormInput
      }
      else if (this.mailmaga.text.length > this.textMax) {
        this.snackbar.message = $literals.MESSAGE.warningTextLengthOver
      }
      else {
        this.snackbar.message = ''
      }

      if (this.snackbar.message) {
        this.snackbar.color = 'warning'
        this.snackbar.open = true
        return
      }

      this.loading = true

      const formData = new FormData()
      formData.append('mailmaga_id', this.mailmaga.mailmaga_id || 0)
      formData.append('title', this.mailmaga.title)
      formData.append('text', this.mailmaga.text)

      this.submitCallback(formData, this.comeBack)
    },
  }
}
</script>

<style scoped>
.wrap-form {
  z-index: 6;
  overflow-y: scroll;
  position: fixed;
  top: 10%;
}
</style>
