<template>
  <v-container fluid class="content-wrap">
    <!-- コンテンツヘッダー -->
    <info-bar
      :btns="[{label:'メルマガ', icon:'plus-box-multiple', tip:'新規メルマガ作成', event:'click'}]"
      @click="openFormRegister('create')"
    >
      <template v-slot:content-info>
        メルアド登録数：{{ targetreach }} ／ 当月送信済：約{{ sentCount }}通
      </template>
    </info-bar>

    <!-- コンテンツボディー（メルマガリスト） -->
    <v-row class="pa-3">
      <v-col v-if="!mailmagas.length" >
        <v-card flat>
          <v-card-text>
            表示するデータがありません。<br />
            画面右上の「メルマガ<v-icon>mdi-plus-box-multiple</v-icon>」からメルマガを作成してください。
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="6" lg="4"
        v-for="(mailmaga, index) in mailmagas"
        :key="mailmaga.mailmaga_id"
      >
        <v-card>
          <v-card-title class="pb-2 d-flex">
            <p class="text-body-2">件名：</p>
            <h4>{{ mailmaga.title }}</h4>
          </v-card-title>

          <v-divider></v-divider>
          <v-card-text>
            <div class="mail-body" v-html="mailmaga.text"></div>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-text>
              {{ statsString(mailmaga) }}
          </v-card-text>

          <!-- スピードダイヤル -->
          <v-speed-dial class="mb-n2 mr-n2"
            v-model="mailmaga.btn"
            absolute bottom right open-on-hover
            direction="left"
            transition="slide-x-reverse-transition"
          >
            <template v-slot:activator>
              <v-btn
                v-model="mailmaga.btn"
                fab small
                color="primary"
              >
                <v-icon v-if="mailmaga.btn">mdi-close</v-icon>
                <v-icon v-else>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-btn
              fab small
              color="primary"
              @click="openFormRegister('update', index)"
            >
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-btn>
            <btn-tip
              tip="メルマガ送信" icon="cellphone-nfc"
              fab small
              elevation="7"
              color="primary"
              @click="openModalConfirm(mailmaga)"
            ></btn-tip>
            <v-btn
              fab small
              color="primary"
              @click="openModalDelete(index)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-speed-dial>
        </v-card>
      </v-col>
    </v-row>

    <!-- メルマガ作成フォーム -->
    <form-register
      ref="formRegister"
      :apiAdmin="apiAdmin"
      :shopData="shopData"
    ></form-register>

    <!-- 確認モーダル -->
    <modal-confirm ref="modalConfirm">
      <div v-html="modalMessage"></div>
    </modal-confirm>

    <!-- 削除モーダル -->
    <modal-delete ref="modalDelete">
      <div v-html="modalMessage"></div>
    </modal-delete>

    <!-- オーバーレイメッセージ -->
    <overlay-message ref="overlayMessage">
      <div v-html="modalMessage"></div>
    </overlay-message>

    <!-- スナックバー -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="true"
    >
      {{ loadingMessage }}
    </loader>

  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import moment from 'moment'
import $literals from '@/literals.js'
import { ApiTool, CheckTokenError } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import BtnWithTip from '@/components/_BtnWithTip.vue'
import ContentInfoBar from '@/components/_ContentInfoBar.vue'
import ModalConfirm from '@/components/_ModalConfirm.vue'
import ModalDelete from '@/components/_ModalDelete.vue'
import OverlayMessage from '@/components/_OverlayMessage.vue'
import FormRegister from '@/components/MailmagaFormRegister.vue'

export default {
  components: {
    'loader': Loader,
    'info-bar': ContentInfoBar,
    'btn-tip': BtnWithTip,
    'modal-confirm': ModalConfirm,
    'modal-delete': ModalDelete,
    'overlay-message': OverlayMessage,
    'form-register': FormRegister,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  //***************************************************
  //データ
  //***************************************************
  data() {
    return {
      mailmagas: [],
      targetreach: 0,
      sentCount: 0,
      modalMessage: '',
      loading: false,
      loadingMessage: '',
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
    }
  },

  //***************************************************
  //算出
  //***************************************************
  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
    statsString() {
      return mailmaga => {
        const sentAt = '送信日: ' + (mailmaga.sent_at ? moment(mailmaga.sent_at).format('M月D日') : '未送信')
        return sentAt + (mailmaga.sent_at ? ' ／ 受信: ' + mailmaga.delivered + ' ／ 開封: ' + mailmaga.opened : '')
      }
    },
  },

  //***************************************************
  //ライフサイクル
  //***************************************************
  created() {
    this.adminApi.setToken(this.serverToken)

    this.loading = true
    this.loadingMessage = 'メルマガ取得中・・・'

    this.getData()
    .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    .then(() => this.loading = false)
  },

  //***************************************************
  //メソッド
  //***************************************************
  methods: {
    async getData() {
      //メアド登録数
      await this.adminApi.getReqWithAuth('email/count/').then( data => this.targetreach = data )

      //送信済累計
      const query = {fromDate: moment().startOf('month').format("YYYY-MM-DD"), toDate: moment(new Date()).format()}
      await this.adminApi.getReqWithAuth('mailmaga/history/', query).then( data => this.sentCount = data )

      if (!this.targetreach) {
        this.modalMessage = '<p>送信先のメールアドレスが一件も登録されていません。<br />'+
                            'サイドメニューの「店舗基本設定」→「メルアド管理」からメールアドレスを登録してください。</p>'
        this.$refs.overlayMessage.open()
      } else {
        await Promise.all([
          this.getMailmagas(),
        ])

        this.setMailmagaStats()
      }
    },

    //新規作成＆更新クリック
    openFormRegister(type, index) {
      const callback = type === 'update' ? this.updateMailmaga : this.createMailmaga
      const formHanddown = {
        formType: type,
        updateData: this.mailmagas[index],
        submitCallback: callback,
        comeBack: { index: index }
      }
      this.$refs.formRegister.open(formHanddown)
    },

    //削除クリック
    openModalDelete(index) {
      this.modalMessage = '<p>以下のメルマガを削除してよろしいですか？</br></br>' + '「' + this.mailmagas[index].title + '」</p>'

      const modalHanddown = {
        submitCallback: this.deleteMailmaga,
        comeBack: { index: index }
      }
      this.$refs.modalDelete.open(modalHanddown)
    },

    //送信クリック
    openModalConfirm(mailmaga) {
      this.modalMessage = '<p>メルマガ「' + mailmaga.title + '」を送信してよろしいですか？</p>'

      const modalHanddown = {
        yesCallback: this.sendMailmaga,
        comeBack: { mailmaga: mailmaga },
        buttonLabel: '送信する',
      }
      this.$refs.modalConfirm.open(modalHanddown)
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    //既存メルマガ取得
    //**************************************************
    getMailmagas() {
      return this.adminApi.getReqWithAuth('mailmaga/').then( results => {
        if (!results || !results.length) return

        results.map( row => {
          if (row.sent_at) {
            row.delivered = 0
            row.opened = 0
          }
          this.mailmagas.push(row)
        })
      })
    },

    //**************************************************
    //メルマガ統計
    //**************************************************
    setMailmagaStats() {
      this.mailmagas.map( mailmaga => {
        if (mailmaga.sent_at) {
          this.adminApi.getReqWithAuth('mailmaga/status/' + mailmaga.mailmaga_id).then( result => {
            if (!result.message) {
              mailmaga.delivered = result.stats[0].delivered.total
              mailmaga.opened = result.stats[0].opened.unique || result.stats[0].opened.total
            }
          })
        }
      })
    },

    //**************************************************
    //メルマガ新規作成
    //**************************************************
    createMailmaga(formData) {
      this.adminApi.apiReqWithData('POST', 'mailmaga/create/', formData).then( response => {
        this.mailmagas.unshift(response)
        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.$refs.formRegister.close() )
    },

    //**************************************************
    //メルマガ更新
    //**************************************************
    updateMailmaga(formData, cameBackData) {
      const apiPartial = 'mailmaga/update/' + this.mailmagas[cameBackData.index].mailmaga_id

      this.adminApi.apiReqWithData('PUT', apiPartial, formData).then( response => {
        if (response.NoRowsAffected) {
          this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoNoRowsAffected, open: true}}
        } else {
          this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
          for (let prop in response) {
            if (prop) this.mailmagas[cameBackData.index][prop] = response[prop]
          }
        }
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.$refs.formRegister.close() )
    },

    //**************************************************
    //メルマガ削除
    //**************************************************
    deleteMailmaga(cameBackData) {
      const apiPartial = 'mailmaga/delete/' + this.mailmagas[cameBackData.index].mailmaga_id

      this.adminApi.apiReqWithData('DELETE', apiPartial).then(() => {
        this.mailmagas.splice(cameBackData.index, 1)
        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.$refs.modalDelete.close() )
    },

    //**************************************************
    //メルマガ送信
    //**************************************************
    sendMailmaga(cameBackData) {
      const apiPartial = 'mailmaga/send/' + cameBackData.mailmaga.mailmaga_id

      this.adminApi.apiReqWithData('POST', apiPartial).then(() => {
        cameBackData.mailmaga.sent_at = new Date()
        cameBackData.mailmaga.delivered = 0
        cameBackData.mailmaga.opened = 0

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successSendSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.$refs.modalConfirm.close() )
    },
  }
}
</script>

<style scoped>
.mail-body {
  height: 500px;
  overflow-y: scroll;
}
</style>
